import React from 'react';
import { createGlobalStyle } from 'styled-components';
// core
import { ASUHeader } from '@asu/component-header-footer/dist/asuHeaderFooter.es.js';

import desktopLogo from '../images/arizona-state-university-logo-vertical.png';
import mobileLogo from '../images/arizona-state-university-logo.png';

import serviceauth from '../auth/serviceauth';

const HeaderStyle = createGlobalStyle`
  header {
    position: sticky;
 
    // this should not be necessary anymore
    // .content-container {
    //   font-size: 1rem;
    //   border: 0;
    //   margin: 0;
    //   height: fit-content;
    //   align-items: start;
    //   flex-direction: row;
    // }

   // link looks better with cursor: pointer;
    a {
      cursor: pointer;
    }
  }
`;

function Header(props) {
  return (
    <>
      <HeaderStyle />
      <ASUHeader
        logo={{
          alt: 'logo',
          src: desktopLogo,
          mobileSrc: mobileLogo,
        }}
        loggedIn={
          sessionStorage.getItem(serviceauth.SS_NAME) &&
          sessionStorage.getItem(serviceauth.SS_JWT_TOKEN)
        }
        userName={sessionStorage.getItem(serviceauth.SS_NAME)}
        logoutLink="/logout"
        loginLink={serviceauth.redirectToServiceauthUrl(true)}
        navTree={props.navTree}
        title="Class Search"
        searchUrl="https://search.asu.edu/search"
      />
    </>
  );
}

export default Header;
