import React from 'react';
import ClassSearch from './ClassSearch';
import CatalogSearch from './CatalogSearch';
import Admin from './admin/AdminTabs';
import Logout from './auth/Logout';
import AdvancedSearch from './AdvancedSearch';
import AdvancedSearchCatalog from './AdvancedSearchCatalog';
import serviceauth from './auth/serviceauth';
// import InstructorDirectoryRedirect from './components/InstructorDirectoryRedirect'

//CSS
import '@asu/unity-bootstrap-theme/dist/css/unity-bootstrap-theme.bundle.css';

import {
  BrowserRouter as Router,
  Route,
  // Switch,
  Redirect,
  Switch,
  useHistory,
} from 'react-router-dom';

import ReactGA from 'react-ga4';
import GoogleTagManager from './components/GoogleTagManager';
import ClassFAQ from './ClassFAQ';
const ENV = process.env.REACT_APP_HOST_ENV;

function getGA() {
  return ENV === 'prod' ? 'G-TEHJR60KD9' : ' ';
}

function getGTM() {
  return ENV === 'prod' ? 'GTM-KDWN8Z' : ' ';
}

ReactGA.initialize(getGA());

const ServiceAuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => handleAuth(<Component {...props} />, props)}
  />
);

function handleAuth(component, props) {
  if (props.history.location.hash !== '') {
    return component;
  } else if (props.history.action === 'PUSH') {
    // console.log("Just filtering, do nothing")
    return component;
  } else if (
    sessionStorage.getItem(serviceauth.SS_JWT_TOKEN) &&
    (sessionStorage.getItem(serviceauth.SS_NAME) === null ||
      sessionStorage.getItem(serviceauth.SS_NAME) === '')
  ) {
    // console.log("getting user data 2")
    serviceauth.authComplete();
    return component;
  } else if (sessionStorage.getItem(serviceauth.SS_PASSIVE_AUTH)) {
    // console.log("passive auth: "+JSON.stringify(props))
    sessionStorage.removeItem(serviceauth.SS_PASSIVE_AUTH);
    return component;
  } else {
    //	console.log("here 3")
    serviceauth.reauth();
  }
}

const ServiceAuthResponseRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => handleAuthResponse(props)} />
);

function handleAuthResponse(props) {
  //console.log(JSON.stringify(props))
  if (
    serviceauth.getParam('code') &&
    (sessionStorage.getItem(serviceauth.SS_JWT_TOKEN) === null ||
      sessionStorage.getItem(serviceauth.SS_JWT_TOKEN) === '')
  ) {
    //console.log("code is in params")
    var code = serviceauth.getParam('code');
    var state = serviceauth.getParam('state');

    serviceauth.handleOauthCode(
      code,
      state,
      function () {
        //console.log("getting user data 1")
        serviceauth.authComplete();
      },
      function (errTxt) {
        // TODO: UI
        console.log('ERROR: ' + errTxt);
        serviceauth.reauth();
      }
    );
    //return redirect
  } else if (serviceauth.getParam('error') === 'unauthenticated') {
    //console.log("coming from serviceaauth with error = unauthenticated")
    sessionStorage.setItem(serviceauth.SS_PASSIVE_AUTH, 'Y');
  }
  //console.log("return redirect")
  var search =
    sessionStorage.getItem(serviceauth.SS_SEARCH_PARAMS) !== null
      ? sessionStorage.getItem(serviceauth.SS_SEARCH_PARAMS)
      : '';

  sessionStorage.removeItem(serviceauth.SS_SEARCH_PARAMS);

  //console.log(search)
  const mySearch = search.split('?');
  if (mySearch !== null && mySearch.length > 1) {
    //console.log("Redirecting with paramaters" + JSON.stringify(newState))
    //console.log("Redirecting with paramaters props: "+JSON.stringify(props))

    return (
      <Redirect
        to={{
          pathname: mySearch[0],
          //state: { referrer: currentLocation },
          search: '?' + mySearch[1],
        }}
      />
    );
  } else {
    //  console.log("Redirecting without paramaters")
    return (
      <Redirect
        to={{
          pathname: mySearch[0],
          state: { from: props.location },
          //search:search
        }}
      />
    );
  }
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      sessionStorage.getItem(serviceauth.SS_JWT_TOKEN) ? (
        <Component {...props} />
      ) : (
        window.location.replace(serviceauth.redirectToServiceauthUrl(true))
      )
    }
  />
);

const AllRoutesRedirect = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Redirect
        to={{
          pathname: '/catalog/classes',
          state: { from: props.location },
        }}
      />
    )}
  />
);

const LocalRedirect = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      myLocalRedirect(
        <Redirect
          to={{
            pathname: '/catalog/classes',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const LocalRedirectAdvanced = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      myLocalRedirect(
        <Redirect
          to={{
            pathname: '/catalog/advanced',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

function myLocalRedirect(redirect) {
  if (window.location.href.indexOf('http://localhost:3000/local') >= 0) {
    sessionStorage.setItem(serviceauth.SS_PASSIVE_AUTH, 'Y');
    return redirect;
  }
}

const CatalogLocalRedirect = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      myLocalRedirect(
        <Redirect
          to={{
            pathname: '/catalog/courses',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const FaqLocalRedirect = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      myLocalRedirect(
        <Redirect
          to={{
            pathname: '/catalog/faq',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const App = {
  App() {
    // console.log(document.cookie.split(";"))
    // sessionStorage.setItem(serviceauth.SS_JWT_TOKEN, "eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJsY2FicmVAYXN1LmVkdSIsInNjb3BlIjpbImh0dHBzOlwvXC9hcGkubXlhc3VwbGF0LWRwbC5hc3UuZWR1XC9zY29wZXNcL3BlcnNvblwvcmVhZDpzZWxmIiwiaHR0cHM6XC9cL2FwaS5teWFzdXBsYXQtZHBsLmFzdS5lZHVcL3Njb3Blc1wvcHJpbmNpcGFsXC9yZWFkOnNlbGYiXSwiaXNzIjoic2VydmljZWF1dGgiLCJhc3VzZXJ2aWNlYXV0aC5jbGllbnRfaWQiOiJjYXRhbG9nLWNsYXNzLXNlYXJjaC1hcHAtbm9ucHJvZCIsImV4cCI6MTY4OTE5OTEzMCwiaWF0IjoxNjg5MTk4NTMwfQ.v44NL-A9sYh18XWPBaZnTmQgVCaXWc2HpBE7oYKNDRWLj09MBO6PpMPmIel8vFdBu7W4f-po9xTiMViGNfqJRfC8GjbFT-hQS-zf_hDA5F7pfPI_4-aQCSYygSSzVY96PN4_R6OAIllwgtjyRBrcuLLWG6qNH5gq1n5678ZFUmZGJNL_I7eJGkB_OW72CflWGCt2fc6ctV8zz9j9TGFVEUKd9kdqvuiCEjTOc-TS6gtBwnfaTF42Uxq8Pb3dRtPmK3-41I9ZwLqTAbv2x_wlXda_dFaBEQuQYXLst8Gh4O3QOvs5xqIGQx9EsbQDVS63iqjKpBntJG1N5HlqF6fZaILQlib9O3DdAM8qn-umouJNPG6mfo312c6weztDiwTn-elcjf-TdionLIyUz-lZxgDS5t56lbucqrAOMlc29ZoVrElppIBcM-A_yiZPFMhEE0bKyqBsLg7NbuKXdKy-iWe__Jd2_EKC9I1suieTseQXghy_hlmZPpyLDci5C-VOFnPKHk9uoqlTwHoFQ7onyJFvAH9Uaso3jiEzUNy4xHia80ESTCqqJccs_LZKKZtBn2akw3wSkvhs4GYYifq3HOxUTqEXUB7T1qI_pHmqo01a3CWhVrFVKTrT1eNnKz8aOHpBdgCmmBT-7rn9Fjx6D-QTR2bYRP8_YGEDflB-eY4");
    // sessionStorage.setItem(serviceauth.SS_VIEW_AS_USER,"xxxx")
    // sessionStorage.setItem(serviceauth.SS_VIEW_AS,"N")
    // sessionStorage.setItem(serviceauth.SS_IS_ONLINE,"N")
    // sessionStorage.setItem(serviceauth.SS_CAREER,"")
    // sessionStorage.setItem(serviceauth.SS_IS_STUDENT,"N")

    // window.dataLayer.push({
    //   event: 'pageview'
    // });

    return (
      <Router>
        <GoogleTagManager gtmId={getGTM()} />
        <Routes />
      </Router>
    );
  },
};

function Routes() {
  const history = useHistory();

  React.useEffect(() => {
    trackPageView(); // To track the first pageview upon load
    history.listen(trackPageView); // To track the subsequent pageviews
  }, [history]);

  function trackPageView() {
    // New with react-ga4, remove pageview() and use send() with object instead
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
      title: window.document.title,
    });
  }

  return (
    <Switch>
      <Route exact path="/" component={AllRoutesRedirect} />
      {/* <Route path="/login" component={Login} /> */}
      <Route path="/logout" component={Logout} />
      {/* <Route path="/instructor" component={InstructorDirectoryRedirect} /> */}
      <PrivateRoute path="/catalog/admin" component={Admin} />
      <Route exact path="/catalog" component={ServiceAuthResponseRoute} />{' '}
      {/*handle response from serviceauth, redirects to /catalog/classes */}
      <ServiceAuthRoute path="/catalog/classes" component={ClassSearch} />
      <LocalRedirect exact path="/local" component={ClassSearch} />
      <CatalogLocalRedirect
        exact
        path="/local/catalog"
        component={CatalogSearch}
      />
      <ServiceAuthRoute path="/catalog/courses" component={CatalogSearch} />
      <LocalRedirectAdvanced
        exact
        path="/localadvanced"
        component={AdvancedSearch}
      />
      <ServiceAuthRoute
        exact
        path="/catalog/advanced"
        component={AdvancedSearch}
      />
      <ServiceAuthRoute
        exact
        path="/catalog/advanced/courses"
        component={AdvancedSearchCatalog}
      />
      <FaqLocalRedirect exact path="/local/faq" component={ClassFAQ} />
      <ServiceAuthRoute path="/catalog/faq" component={ClassFAQ} />
      {/* <Route exact path='/FAQ' component={() => {
          window.location.href = 'https://docs.google.com/document/d/e/2PACX-1vRskOVXbAyDzc_rSXaKzqGouKxajil92K2InHxL43vzAkqz6Vp3vpPoKkZWdhiDBYcPnHicMCnRRETl/pub';
          return null;
        }}/> */}
      {/* <PrivateRoute path='/catalog/courses' component={CatalogSearch} /> */}
    </Switch>
  );
}

// function AllRoutesRedirect() {
//   return <Redirect
//   to={{
//     pathname: "/catalog/classes",
//     state: { from: location }
//   }}
// />
// }

export default App;
