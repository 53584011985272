import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import UtilFunctions from './UtilFunctions';
import './css/homesearch.css';
import './css/faq.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import serviceauth from './auth/serviceauth';
import isEmpty from 'lodash.isempty';
import ClassAccordion from './components/ClassAccordion';
import Background from './images/faq-page/faq-background.png';
import exploreClassByGS from './images/faq-page/explore-class-by-gs.png';
import moreFiltersPopup from './images/faq-page/more-filters-popup.png';
import moreFiltersPopupFull from './images/faq-page/more-filters-popup-full.png';
import advSearchGS from './images/faq-page/adv-search-gs.png';
import classSearchHeader from './images/faq-page/class-search-header.png';
import classSearchInput from './images/faq-page/class-search-input.png';
import advSearch from './images/faq-page/adv-search.png';
import classSearchResults from './images/faq-page/search-results.png';
import whereToStartBoxes from './images/faq-page/where-to-start-boxes.png';
import courseCatalogInput from './images/faq-page/course-catalog-input.png';
import greenCircle from './images/faq-page/green-circle.png';
import greenTriangle from './images/faq-page/green-triangle.png';
import redX from './images/faq-page/red-x.png';
import { AnchorMenu } from '@asu/unity-react-core';
import '@fortawesome/fontawesome-free/css/all.css';

const jumbotronBackground = {
  backgroundPosition: '0% 68%',
  backgroundSize: 'cover',
  background:
    'linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url(' +
    Background +
    ')',
};

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keywords: '',
      navTree: [],
      turnOffApp: false,
      width: window.innerWidth,
      height: window.innerHeight,
    };

    this.handleResize = this.handleResize.bind(this);
  }

  async componentDidMount() {
    window.document.title =
      'Class Search | Guide and Frequently Asked Questions | Arizona State University';
    window.scrollTo(0, 0);
    window.addEventListener('resize', this.handleResize);

    // const appSettings = await UtilFunctions.getAppSettings()
    fetch(serviceauth.getRequestObject(UtilFunctions.getTermListUrl(), 'GET'))
      .then((response) => {
        if (response.status === 401) {
          serviceauth.reauth();
        }
        return response.json();
      })
      .then(async (termList) => {
        //update fields from url
        const data = UtilFunctions.parseUrl(this.props.location.search);
        const term = isEmpty(data.term)
          ? termList.currentTerm[0].value
          : data.term;
        this.setState({
          term: term,
          navTree: await UtilFunctions.getHeaderNavTree(term, 'faq'),
        });
      });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  checkLandscape(width, height) {
    return width > height;
  }

  handleScrollTo = (eleId) => {
    // Scroll to the target element
    const targetElement = document.getElementById(eleId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  render() {
    const width = this.state.width;
    const height = this.state.height;
    const isLandscape = this.checkLandscape(width, height);
    let faqBootstrapCols;

    // Widen the cols for mobile landscape viewport
    if (isLandscape && width < 1281) {
      faqBootstrapCols = 'col-lg-10 col-md-10 col-sm-12';
    } else {
      // default cols views
      faqBootstrapCols = 'col-lg-6 col-md-10 col-sm-4';
    }

    return (
      <React.Fragment>
        <Container fluid={true} className="p-0">
          <Header navTree={this.state.navTree} />
          <section
            style={jumbotronBackground}
            className="mb-0 pt-10 pb-10"
            id="main"
          >
            <Container>
              <Row>
                <Col xs={'auto'} className="p-0">
                  <h1 className="mt-0" id="skip-to-content">
                    <span className="highlight-white">
                      ASU Class Search Guide
                    </span>
                  </h1>
                </Col>
              </Row>
            </Container>
          </section>

          {/* After Header Menu */}
          <div className="container">
            <div className="row row-spaced pt-2 pb-2">
              <div className="col col-12 col-md-12 col-lg-12">
                <AnchorMenu
                  firstElementId="first-container"
                  items={[
                    {
                      icon: ['fas', 'book-open'],
                      targetIdName: 'first-container',
                      text: 'ASU Terms and Definitions',
                    },
                    {
                      icon: ['fas', 'search'],
                      targetIdName: 'second-container',
                      text: 'How to Use Class Search',
                    },
                    {
                      icon: ['fas', 'graduation-cap'],
                      targetIdName: 'third-container',
                      text: 'General Studies',
                    },
                  ]}
                />

                {/*Containers*/}
                {/*Main Body */}
                <div className="container-fluid pr-0 pl-0">
                  <Container className="pt-5 pb-5 mb-5 ps-0 pe-0">
                    <div className="row">
                      {/*<div className="col-lg-6 col-md-10 col-sm-4">*/}
                      <div className={faqBootstrapCols}>
                        <p className="bigTitle mb-4 mt-4">
                          Frequently asked questions and guide
                        </p>
                        <p className="text">
                          As you prepare to enroll in classes, learn how to
                          search for courses, review ASU terminology and more.
                          You can filter the list of classes to focus on just
                          the ones you want or need. Work your way through this
                          guide and you’ll be a pro in no time!
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className={faqBootstrapCols}>
                        <p className="bigTitle mb-4 mt-4">
                          ASU terms and definitions
                        </p>
                        <span id="first-container"></span>
                        <p className="text">
                          As you search for classes, you may encounter new words
                          and concepts. Discover important concepts for Class
                          Search and Course Catalog below. For more information,
                          review ASU’s{' '}
                          <a href="https://catalog.asu.edu/glossary">
                            glossary of academic terms
                          </a>{' '}
                          and{' '}
                          <a href="https://provost.asu.edu/curriculum-development/curriculum-management/definitions">
                            definitions in our curriculum
                          </a>
                          .
                        </p>
                      </div>
                    </div>

                    <ClassAccordion
                      accordionTitle="Academic level"
                      accordionId="card1"
                    >
                      <div className={faqBootstrapCols}>
                        <br />
                        <p>
                          ASU offers courses to complete both undergraduate and
                          graduate degrees. Visit ASU’s{' '}
                          <a href="https://degrees.apps.asu.edu/bachelors">
                            Degree Search tool
                          </a>{' '}
                          to explore more than 800 accredited, top-ranked degree
                          programs in our many locations and learning
                          environments.
                        </p>
                        <p> Course numbers indicate academic level.</p>

                        <ul style={{ paddingLeft: '1rem' }}>
                          <li className="mt-1">
                            <b>Undergraduate:</b> Courses numbered from 100 to
                            499 are designed for undergraduate students.
                          </li>
                          <li className="mt-1">
                            <b>Lower Division:</b> Courses numbered from 100 to
                            299 are designed primarily for freshmen and
                            sophomores.
                          </li>
                          <li className="mt-1">
                            <b>Upper Division:</b> Courses numbered from 300 to
                            499 are designed primarily for juniors and seniors.
                          </li>
                          <li className="mt-1">
                            <b>Graduate:</b> Courses numbered from 500 to 799
                            are designed for graduate students.
                          </li>
                        </ul>
                      </div>
                    </ClassAccordion>

                    <ClassAccordion
                      accordionTitle="Course type"
                      accordionId="card2"
                    >
                      <div className={faqBootstrapCols}>
                        <br />
                        <p>ASU offers courses in several instruction modes.</p>
                        <ul style={{ paddingLeft: '1rem' }}>
                          <li className="mt-1">
                            <b>ASU Online:</b> Courses only for ASU Online
                            students. They are 100% online and asynchronous (no
                            class meeting days and times).
                          </li>
                          <li className="mt-1">
                            <b>iCourses:</b> Courses designed to be taken
                            entirely online and asynchronously for the duration
                            of the session. Only on-campus students can enroll
                            in iCourses.
                          </li>
                          <li className="mt-1">
                            <b>In-person:</b> Courses offered at a physical
                            location on designated days and times (synchronous).
                            Only on-campus students can enroll in in-person
                            courses.
                          </li>
                          <li className="mt-1">
                            <b>Hybrid:</b> Courses that blend in-person and
                            online learning. Typically the course will require a
                            once-a-week meeting at a physical location with
                            additional remote learning. Only on-campus students
                            can enroll in hybrid courses.
                          </li>
                          <li className="mt-1">
                            <b>ASU Sync:</b> Courses taught online and
                            synchronously at designated days and times. Only
                            on-campus students can enroll in Sync courses.
                          </li>
                        </ul>
                      </div>
                    </ClassAccordion>

                    <ClassAccordion
                      accordionTitle="Session"
                      accordionId="card3"
                    >
                      <div className={faqBootstrapCols}>
                        <br />
                        <p>
                          ASU offers classes in the fall, spring and summer
                          semesters divided into multi-week sessions. Each
                          session has different dates for important university
                          processes (such as enrolling classes, dropping a
                          class, finals, etc.).
                        </p>
                        <ul style={{ paddingLeft: '1rem' }}>
                          <li className="mt-1">
                            <b>Session A:</b> First half (7.5 weeks) of the
                            semester
                          </li>
                          <li className="mt-1">
                            <b>Session B:</b> Second half (7.5 weeks) of the
                            semester
                          </li>
                          <li className="mt-1">
                            <b>Session C:</b> Full (15-week) semester
                          </li>
                        </ul>
                        <p>
                          Consult the{' '}
                          <a href="https://registrar.asu.edu/academic-calendar">
                            Academic Calendar
                          </a>{' '}
                          for more information.
                        </p>
                      </div>
                    </ClassAccordion>

                    {/*Start how to use Class Search*/}
                    <div className="row">
                      <div className={faqBootstrapCols}>
                        <p className="bigTitle mb-4 mt-4">
                          How to use Class Search
                        </p>
                        <span id="second-container"></span>
                      </div>
                    </div>

                    <ClassAccordion
                      accordionTitle="Starting a new search"
                      accordionId="card4"
                    >
                      <div className={faqBootstrapCols}>
                        <br />
                        <p>
                          To start a new search, click on the{' '}
                          <a href="https://catalog.apps.asu.edu/catalog/classes">
                            Home icon
                          </a>{' '}
                          in the top left corner, and everything will reset for
                          you. If you previously added any classes to your
                          schedule, they will remain in your cart. This will
                          just reset your search and return you to the homepage.
                        </p>

                        <div style={{ maxWidth: '300px' }}>
                          <div className="uds-img">
                            <img
                              src={classSearchHeader}
                              className="uds-img img-fluid"
                              alt="Class Search input section"
                              width="800"
                              height="600"
                              loading="lazy"
                              decoding="async"
                              fetchpriority="high"
                            />
                          </div>
                        </div>

                        <br />
                        <p>
                          Choose classes by using the search fields. Enter the
                          subject, course number or keyword into the appropriate
                          box and click the Search Classes button. A list of
                          filtered classes will appear.
                        </p>

                        <br />
                        <div className="uds-img">
                          <img
                            src={classSearchInput}
                            className="uds-img img-fluid"
                            alt="Class Search input section"
                            width="800"
                            height="600"
                            loading="lazy"
                            decoding="async"
                            fetchpriority="high"
                          />
                        </div>
                      </div>
                    </ClassAccordion>

                    <ClassAccordion
                      accordionTitle="Keyword search"
                      accordionId="card5"
                    >
                      <div className={faqBootstrapCols}>
                        <br />
                        <p>
                          Keyword Search is helpful when you aren’t sure what
                          you are looking for. Keyword Search looks at the
                          formal subject name first, and if it doesn’t find a
                          match, it will then look at other titles, topics and
                          instructors until it finds it for you.
                        </p>
                        <b>You can search for things like:</b>
                        <ul style={{ paddingLeft: '1rem' }}>
                          <li className="mt-1">
                            Subject abbreviations (e.g. ENG, PHY)
                          </li>
                          <li className="mt-1">
                            General subject titles (e.g. English, Physics)
                          </li>
                          <li className="mt-1">
                            Subject abbreviation and number (e.g. ENG 101, PHY
                            113)
                          </li>
                          <li className="mt-1">
                            Specific subject titles (e.g. First-year
                            composition, physics lab)
                          </li>
                          <li className="mt-1">
                            Official class numbers (if you know them, otherwise
                            leave this blank). Always a five-digit number.
                          </li>
                          <li className="mt-1">Instructor name (e.g. Smith)</li>
                          <li className="mt-1">
                            Subject and instructor name (e.g. English Smith)
                          </li>
                        </ul>
                        <b>Important tips:</b>
                        <ul style={{ paddingLeft: '1rem' }}>
                          <li className="mt-1">
                            If you enter a five-digit number, Keyword Search
                            will assume it is an official class number and
                            disregard other keyword terms.
                          </li>
                          <li className="mt-1">
                            No need to add “ors” and “ands.” If you type in more
                            than one keyword search term, Keyword Search will
                            look for anything like both words.
                          </li>
                        </ul>
                      </div>
                    </ClassAccordion>

                    <ClassAccordion
                      accordionTitle="Advanced search"
                      accordionId="card6"
                    >
                      <div className={faqBootstrapCols}>
                        <br />
                        <p>
                          Next to the Search Classes button is an{' '}
                          <a href="https://catalog.apps.asu.edu/catalog/advanced">
                            Advanced Search link,
                          </a>{' '}
                          which lets you set every filter in one convenient
                          screen.
                        </p>

                        <div className="uds-img">
                          <img
                            src={classSearchInput}
                            className="uds-img img-fluid"
                            alt="Class Serch input section"
                            width="800"
                            height="600"
                            loading="lazy"
                            decoding="async"
                            fetchpriority="high"
                          />
                        </div>
                        <br />
                        <div className="uds-img">
                          <img
                            src={advSearch}
                            className="uds-img img-fluid"
                            alt="Advaned search showing general studies search inputs"
                            width="800"
                            height="600"
                            loading="lazy"
                            decoding="async"
                            fetchpriority="high"
                          />
                        </div>
                      </div>
                    </ClassAccordion>

                    <ClassAccordion
                      accordionTitle="How to refine and understand the results of your search"
                      accordionId="card7"
                    >
                      <div className="row">
                        <div className={faqBootstrapCols}>
                          <br />
                          <p>
                            After you have performed a search, you will see a
                            list of results. From here, you can filter further
                            or expand the classes for more information on each
                            class.
                          </p>

                          <ul style={{ paddingLeft: '1rem' }}>
                            <li className="mt-1">
                              Click the drop-down menus above the search results
                              to filter more, resulting in fewer classes
                              displaying on your list.
                            </li>
                            <li className="mt-1">
                              The “More Filters” option is very helpful in
                              focusing your search to just the right classes.
                            </li>
                            <li className="mt-1">
                              Click on the Course or Title text to see more
                              course information display below the row.
                            </li>
                            <li className="mt-1">
                              Click on an instructor’s name to open their ASU
                              Web Directory profile in a new browser tab/window.
                            </li>
                            <li className="mt-1">
                              Click on a location to open a campus map in a new
                              browser tab/window.
                            </li>
                          </ul>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 col-md-10 col-sm-4">
                            <div className="uds-img">
                              <img
                                src={classSearchResults}
                                className="uds-img img-fluid"
                                alt="Results from Class Search"
                                width="800"
                                height="600"
                                loading="lazy"
                                decoding="async"
                                fetchpriority="high"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </ClassAccordion>

                    <ClassAccordion
                      accordionTitle="Seat availability"
                      accordionId="card8"
                    >
                      <div className={faqBootstrapCols}>
                        <br />
                        <p>
                          You may notice different icons near seats for each
                          class. There are three different possible icons that
                          correspond to seat availability for that class.
                        </p>

                        <p>
                          <b>Green circle:</b> This class has open seats. You
                          can add it to your schedule.
                        </p>
                        <div style={{ maxWidth: '115px' }}>
                          <div className="uds-img">
                            <img
                              src={greenCircle}
                              className="uds-img img-fluid"
                              alt="Green circle for open seats"
                              width="800"
                              height="600"
                              loading="lazy"
                              decoding="async"
                              fetchpriority="high"
                            />
                          </div>
                        </div>

                        <br />
                        <p>
                          <b>Green triangle:</b> Seats in this class have been
                          reserved for students in specified programs, majors or
                          groups and are subject to change without notice. You
                          may not be able to add. Contact your advisor for next
                          steps.
                        </p>
                        <div style={{ maxWidth: '115px' }}>
                          <div className="uds-img">
                            <img
                              src={greenTriangle}
                              className="uds-img img-fluid"
                              alt="Green triangle for reserved seats"
                              width="800"
                              height="600"
                              loading="lazy"
                              decoding="async"
                              fetchpriority="high"
                            />
                          </div>
                        </div>

                        <br />
                        <p>
                          <b>Red 'x':</b> This class has zero open seats. It is
                          full. You will not be able to add at this time.
                        </p>
                        <div style={{ maxWidth: '115px' }}>
                          <div className="uds-img">
                            <img
                              src={redX}
                              className="uds-img img-fluid"
                              alt="Red X showing no open seats"
                              width="800"
                              height="600"
                              loading="lazy"
                              decoding="async"
                              fetchpriority="high"
                            />
                          </div>
                        </div>
                      </div>
                    </ClassAccordion>

                    <ClassAccordion
                      accordionTitle="Other ways to search"
                      accordionId="card9"
                    >
                      <div className={faqBootstrapCols}>
                        <br />
                        <p>
                          If you would like to explore new class subjects, then
                          click on the “Class Quiz” or “Browse by Subject” boxes
                          on the{' '}
                          <a href="https://catalog.apps.asu.edu/catalog/classes">
                            homepage
                          </a>
                          . This will display the classes which fit in that
                          category.
                        </p>

                        <div className="uds-img">
                          <img
                            src={whereToStartBoxes}
                            className="uds-img img-fluid"
                            alt="Class boxes on homepage"
                            width="800"
                            height="600"
                            loading="lazy"
                            decoding="async"
                            fetchpriority="high"
                          />
                        </div>
                        <br />
                        <p>
                          You can also explore classes by session, general
                          studies requirements or academic level on the
                          homepage. No matter how you browse, explore or search
                          classes, you will end up with a list of relevant
                          courses.
                        </p>
                      </div>
                    </ClassAccordion>

                    <ClassAccordion
                      accordionTitle="Searching with ASU's Course Catalog"
                      accordionId="card10"
                    >
                      <div className={faqBootstrapCols}>
                        <br />
                        <p>
                          The{' '}
                          <a href="https://catalog.apps.asu.edu/catalog/courses">
                            Course Catalog Search application
                          </a>{' '}
                          is a tool for students interested in applying to ASU
                          (as well as current students) to review ASU’s
                          extensive course list to see what courses were
                          available in the past and might be offered in the
                          future. It is similar to the paper academic course
                          catalog one might use at other universities (except
                          this is searchable).
                        </p>

                        <p>It can be used to review:</p>

                        <ul style={{ paddingLeft: '1rem' }}>
                          <li className="mt-1">Course descriptions.</li>
                          <li className="mt-1">Prerequisites.</li>
                          <li className="mt-1">Credit hour options.</li>
                          <li className="mt-1">
                            General studies requirements.
                          </li>
                        </ul>

                        <div className="uds-img">
                          <img
                            src={courseCatalogInput}
                            className="uds-img img-fluid"
                            alt="Course Catalog input section"
                            width="800"
                            height="600"
                            loading="lazy"
                            decoding="async"
                            fetchpriority="high"
                          />
                        </div>

                        <p style={{ marginTop: '1rem' }}>
                          To search for class offerings and specific details of
                          when and where the course will be offered, please
                          visit ASU’s{' '}
                          <a href="https://catalog.apps.asu.edu/catalog/classes">
                            Class Search
                          </a>
                          .
                        </p>
                      </div>
                    </ClassAccordion>
                    {/*End how to use Class Search*/}

                    {/*Start General Studies Accordions*/}
                    <div className="row">
                      <div className={faqBootstrapCols}>
                        <p className="bigTitle mb-4 mt-4">General studies</p>
                        <span id="third-container"></span>
                      </div>
                    </div>

                    <ClassAccordion
                      accordionTitle="What are general studies?"
                      accordionId="card11"
                    >
                      <div className="row">
                        <div className={faqBootstrapCols}>
                          <br />
                          <p>
                            <a href="https://catalog.asu.edu/ug_gsr">
                              General studies
                            </a>{' '}
                            classes fulfill undergraduate graduation
                            requirements. ASU’s general studies curriculum
                            enables students to develop critical skills in a
                            diverse set of required categories.
                          </p>
                          <p>
                            <strong>General Studies Gold</strong>
                            <br />
                            General Studies Gold is ASU’s newest general studies
                            curriculum, which specifies the requirements for
                            undergraduate students in catalog year 2024-2025 and
                            forward. In most cases, a student’s catalog year
                            refers to the academic year they first started
                            attending ASU. A student can view their catalog year
                            and general studies designation in the My Programs
                            box on their{' '}
                            <a href="https://my.asu.edu/">My ASU</a>.
                          </p>
                          <p>
                            The General Studies Gold curriculum consists of 35
                            credit hours across nine different categories.
                            Additionally, students are required to complete
                            first-year composition.
                          </p>

                          <strong>
                            Required categories for General Studies Gold:
                          </strong>
                          <ul style={{ paddingLeft: '1rem' }}>
                            <li className="mt-1">
                              <b>HUAD:</b> Humanities, Arts and Design (6
                              credits)
                            </li>
                            <li className="mt-1">
                              <b>SOBE:</b> Social and Behavioral Sciences (3
                              credits)
                            </li>
                            <li className="mt-1">
                              <b>SCIT:</b> Scientific Thinking in Natural
                              Sciences (8 credits)
                            </li>
                            <li className="mt-1">
                              <b>QTRS:</b> Quantitative Reasoning (3 credits)
                            </li>
                            <li className="mt-1">
                              <b>MATH:</b> Mathematics (3 credits)
                            </li>
                            <li className="mt-1">
                              <b>AMIT:</b> American Institutions (3 credits)
                            </li>
                            <li className="mt-1">
                              <b>CIVI:</b> Governance and Civic Engagement (3
                              credits)
                            </li>
                            <li className="mt-1">
                              <b>GCSI:</b> Global Communities, Societies and
                              Individuals (3 credits)
                            </li>
                            <li className="mt-1">
                              <b>SUST:</b> Sustainability (3 credits)
                            </li>
                          </ul>

                          <p>
                            <strong>General Studies Maroon</strong>
                            <br />
                            Students with a catalog year of 2023-2024 or earlier
                            will <b>not</b> need to complete the new General
                            Studies Gold requirements. They should continue to
                            complete the curriculum outlined in{' '}
                            <a href="https://catalog.asu.edu/ug_gsr#general_studies_maroon">
                              General Studies Maroon
                            </a>
                            .
                          </p>

                          <strong>
                            Core knowledge areas for General Studies Maroon:
                          </strong>
                          <ul style={{ paddingLeft: '1rem' }}>
                            <li className="mt-1">
                              <b>HU:</b> Humanities, Arts and Design{' '}
                              <b>and SB:</b> Social-Behavioral Sciences
                              (combined 12 credits)
                            </li>
                            <li className="mt-1">
                              <b>SQ and SG:</b> Natural Sciences (combined 8
                              credits)
                            </li>
                            <li className="mt-1">
                              <b>MA and CS:</b> Mathematical Studies (combined 6
                              credits)
                            </li>
                            <li className="mt-1">
                              <b>L:</b> Literacy and Critical Inquiry (3
                              credits)
                            </li>
                          </ul>

                          <strong>
                            Awareness areas for General Studies Maroon:
                          </strong>
                          <ul style={{ paddingLeft: '1rem' }}>
                            <li className="mt-1">
                              <b>C:</b> Cultural Diversity in the United States
                            </li>
                            <li className="mt-1">
                              <b>G:</b> Global Awareness
                            </li>
                            <li className="mt-1">
                              <b>MA and CS:</b> Mathematical Studies (combined 6
                              credits)
                            </li>
                            <li className="mt-1">
                              <b>H:</b> Historical Awareness
                            </li>
                          </ul>

                          <p>
                            Students with questions about their general studies
                            requirements should contact their academic advisor,
                            who is listed in their Academic Support Team box in{' '}
                            <a href="https://my.asu.edu/">My ASU</a>.
                          </p>
                        </div>
                      </div>
                    </ClassAccordion>

                    <ClassAccordion
                      accordionTitle="Explore classes by general studies"
                      accordionId="card12"
                    >
                      <div className={faqBootstrapCols}>
                        <br />
                        <p>
                          On ASU’s{' '}
                          <a href="https://catalog.apps.asu.edu/catalog/classes">
                            Class Search
                          </a>
                          , you can find courses that meet general studies
                          requirements by clicking on the categories below the
                          “Explore classes by General Studies” section.
                        </p>
                        <p>
                          Undergraduate students in catalog year 2024-2025 or
                          later should reference the General Studies Gold
                          requirements. Those with a catalog year of 2023-2024
                          or earlier should reference the General Studies Maroon
                          requirements.
                        </p>

                        <div className="uds-img">
                          <img
                            src={exploreClassByGS}
                            className="uds-img img-fluid"
                            alt="General studies cards"
                            width="800"
                            height="600"
                            loading="lazy"
                            decoding="async"
                            fetchpriority="high"
                          />
                        </div>
                      </div>
                    </ClassAccordion>

                    <ClassAccordion
                      accordionTitle="Filter search results by general studies"
                      accordionId="card13"
                    >
                      <div className={faqBootstrapCols}>
                        <br />
                        <p>
                          You can narrow your class search results by applying
                          the “More Filters” feature after searching for
                          classes. Select general studies requirements within
                          your general studies curriculum on the “More Filters”
                          pop-up.
                        </p>

                        <div style={{ maxWidth: '300px' }}>
                          <div className="uds-img">
                            <img
                              src={moreFiltersPopup}
                              className="uds-img img-fluid"
                              alt="Filters pop up button"
                              width="100"
                              height="75"
                              loading="lazy"
                              decoding="async"
                              fetchpriority="high"
                            />
                          </div>
                        </div>

                        <br />

                        <div className="uds-img">
                          <img
                            src={moreFiltersPopupFull}
                            className="uds-img img-fluid"
                            alt="Filters popup window"
                            width="800"
                            height="600"
                            loading="lazy"
                            decoding="async"
                            fetchpriority="high"
                          />
                        </div>
                      </div>
                    </ClassAccordion>

                    <ClassAccordion
                      accordionTitle="Advanced search using general studies"
                      accordionId="card14"
                    >
                      <div className={faqBootstrapCols}>
                        <br />
                        <p>
                          The{' '}
                          <a href="https://catalog.apps.asu.edu/catalog/advanced">
                            Advanced Search
                          </a>{' '}
                          feature provides you with options to select general
                          studies required courses for your search.
                        </p>

                        <div className="uds-img">
                          <img
                            src={advSearchGS}
                            className="uds-img img-fluid"
                            alt="Advaned search showing GS search inputs"
                            width="800"
                            height="600"
                            loading="lazy"
                            decoding="async"
                            fetchpriority="high"
                          />
                        </div>
                      </div>
                    </ClassAccordion>
                    {/*End General studies Accordions*/}
                  </Container>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </Container>
      </React.Fragment>
    );
  }
}

export default FAQ;
