import React from 'react';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';

class GoogleTagManager extends React.Component {
  componentDidMount() {
    const tagManagerArgs = {
      gtmId: this.props.gtmId,
      dataLayerName: this.props.dataLayerName || 'dataLayer',
      events: this.props.additionalEvents || {},
    };

    TagManager.initialize(tagManagerArgs);
  }

  render() {
    return null; // No need to render additional divs or scripts
  }
}

GoogleTagManager.propTypes = {
  gtmId: PropTypes.string.isRequired,
  dataLayerName: PropTypes.string,
  additionalEvents: PropTypes.object,
};

export default GoogleTagManager;
