import React from 'react';
import { ASUFooter } from '@asu/component-header-footer/dist/asuHeaderFooter.es.js';
import styled from 'styled-components';

const FooterSection = styled.div`
  footer {
    .footer-innovation-links {
      a {
        order: 2;
      }

      nav {
        order: 1;
      }
    }
`;
function Footer() {
  return (
    <FooterSection className="app-footer">
      <div id="feedback-section">
        <div className="container">
          <div className="row pt-5">
            <div className="col-sm-2">
              <p>ASU Class Search and Course Catalog Search</p>
              <a
                href="https://webapp4.asu.edu/feedback/feedback/catalog"
                className="btn btn-md btn-maroon"
              >
                Send Feedback
              </a>
            </div>
          </div>
        </div>
      </div>
      <ASUFooter />
    </FooterSection>
  );
}

export default Footer;
